import type { AppRouteBeforeServerRenderContext } from 'src/shared/routes';

export const guidesToRedirect = [
	'what-is-a-petition',
	'petition-templates-and-samples',
	'how-to-write-a-petition',
	'do-online-petitions-work',
];

/**
 * Handles redirecting select guides pages to the new petition guides in corgi.
 */
export const handleGuidesRedirect = ({ redirect, params: { slug } }: AppRouteBeforeServerRenderContext) => {
	if (!guidesToRedirect.includes(slug)) return;

	redirect(`/petition-guides/${slug}`, true);
};
